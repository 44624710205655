import { cilClone, cilCommentBubbleQuestion, cilFileArchive, cilSearch } from '@coreui/icons-pro';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { lazy } from 'react';
import {
  collectionMapping, COLLECTION_LEARNING_FEATURE,
  COLLECTION_LESSON_QA,
  COLLECTION_PRACTICE_EXAM,
  COLLECTION_TARGETED_REVIEW,
  COLLECTION_TRUE_FALSE
} from '../../constants';
import Clone from '../../pages/questions/Clone';
import Export from '../../pages/questions/Export';
import { RouteProps } from '../index';


const Search = lazy(() => import('../../pages/questions/Search'));
const Overview = lazy(() => import('../../pages/questions/Overview'));
const Form = lazy(() => import('../../pages/questions/Form'));
const Exports = lazy(() => import('../../pages/questions/Exports'));

export const questionsRoutes: RouteProps[] = [
  { path: '/questions', exact: true, name: 'Questions', protect: true, permissions: ['questions:view'] },

  {
    path: '/questions/search',
    exact: true,
    name: 'Search',
    component: Search,
    protect: true,
    permissions: ['questions:view', 'questions:search'],
  },

  {
    path: '/questions/lesson-qa',
    exact: true,
    name: 'Lesson Q&A',
  },
  {
    path: '/questions/lesson-qa/overview',
    exact: true,
    name: 'Overview',
    component: Overview,
    componentProps: { collection: COLLECTION_LESSON_QA },
    protect: true,
  },
  {
    path: '/questions/lesson-qa/create',
    exact: true,
    name: 'Create',
    component: Form,
    componentProps: { collection: COLLECTION_LESSON_QA },
    protect: true,
  },
  {
    path: '/questions/lesson-qa/edit',
    exact: true,
    name: 'Edit',
    protect: true,
  },
  {
    path: '/questions/lesson-qa/edit/:id',
    exact: true,
    name: 'Edit',
    component: Form,
    componentProps: { collection: COLLECTION_LESSON_QA },
    protect: true,
  },
  {
    path: '/questions/lesson-qa/export',
    exact: true,
    name: 'Export',
    component: Export,
    componentProps: { collection: COLLECTION_LESSON_QA, withSections: true },
    protect: true,
  },

  {
    path: '/questions/practice-exam',
    exact: true,
    name: 'Practice Exam',
  },
  {
    path: '/questions/practice-exam/overview',
    exact: true,
    name: 'Overview',
    component: Overview,
    componentProps: { collection: COLLECTION_PRACTICE_EXAM },
    protect: true,
  },
  {
    path: '/questions/practice-exam/create',
    exact: true,
    name: 'Create',
    component: Form,
    componentProps: { collection: COLLECTION_PRACTICE_EXAM },
    protect: true,
  },
  {
    path: '/questions/practice-exam/edit',
    exact: true,
    name: 'Edit',
    protect: true,
  },
  {
    path: '/questions/practice-exam/edit/:id',
    exact: true,
    name: 'Edit',
    component: Form,
    componentProps: { collection: COLLECTION_PRACTICE_EXAM },
    protect: true,
  },
  {
    path: '/questions/practice-exam/export',
    exact: true,
    name: 'Export',
    component: Export,
    componentProps: { collection: COLLECTION_PRACTICE_EXAM, allowShuffle: true },
    protect: true,
  },

  {
    path: '/questions/targeted-review',
    exact: true,
    name: 'Targeted Review',
  },
  {
    path: '/questions/targeted-review/overview',
    exact: true,
    name: 'Overview',
    component: Overview,
    componentProps: { collection: COLLECTION_TARGETED_REVIEW },
    protect: true,
  },
  {
    path: '/questions/targeted-review/create',
    exact: true,
    name: 'Create',
    component: Form,
    componentProps: { collection: COLLECTION_TARGETED_REVIEW },
    protect: true,
  },
  {
    path: '/questions/targeted-review/edit',
    exact: true,
    name: 'Edit',
    protect: true,
  },
  {
    path: '/questions/targeted-review/edit/:id',
    exact: true,
    name: 'Edit',
    component: Form,
    componentProps: { collection: COLLECTION_TARGETED_REVIEW },
    protect: true,
  },
  {
    path: '/questions/targeted-review/export',
    exact: true,
    name: 'Export',
    component: Export,
    componentProps: { collection: COLLECTION_TARGETED_REVIEW },
    protect: true,
  },

  {
    path: '/questions/true-false',
    exact: true,
    name: 'True/False',
  },
  {
    path: '/questions/true-false/overview',
    exact: true,
    name: 'Overview',
    component: Overview,
    componentProps: { collection: COLLECTION_TRUE_FALSE },
    protect: true,
  },
  {
    path: '/questions/true-false/create',
    exact: true,
    name: 'Create',
    component: Form,
    componentProps: { collection: COLLECTION_TRUE_FALSE, simple: true },
    protect: true,
  },
  {
    path: '/questions/true-false/edit',
    exact: true,
    name: 'Edit',
    protect: true,
  },
  {
    path: '/questions/true-false/edit/:id',
    exact: true,
    name: 'Edit',
    component: Form,
    componentProps: { collection: COLLECTION_TRUE_FALSE, simple: true },
    protect: true,
  },
  {
    path: '/questions/true-false/export',
    exact: true,
    name: 'Export',
    component: Export,
    componentProps: { collection: COLLECTION_TRUE_FALSE, simple: true },
    protect: true,
  },

  {
    path: '/questions/learning-feature',
    exact: true,
    name: 'Learning Feature',
  },
  {
    path: '/questions/learning-feature/overview',
    exact: true,
    name: 'Overview',
    component: Overview,
    componentProps: { collection: COLLECTION_LEARNING_FEATURE },
    protect: true,
  },
  {
    path: '/questions/learning-feature/create',
    exact: true,
    name: 'Create',
    component: Form,
    componentProps: { collection: COLLECTION_LEARNING_FEATURE, richText: true, answerFeedback: true, embedImage: true },
    protect: true,
  },
  {
    path: '/questions/learning-feature/edit',
    exact: true,
    name: 'Edit',
    protect: true,
  },
  {
    path: '/questions/learning-feature/edit/:id',
    exact: true,
    name: 'Edit',
    component: Form,
    componentProps: { collection: COLLECTION_LEARNING_FEATURE, richText: true, answerFeedback: true, embedImage: true },
    protect: true,
  },
  {
    path: '/questions/learning-feature/export',
    exact: true,
    name: 'Export',
    component: Export,
    componentProps: { collection: COLLECTION_LEARNING_FEATURE, originalNumbering: true },
    protect: true,
  },

  {
    path: '/questions/exports',
    exact: true,
    name: 'Exports',
    component: Exports,
    protect: true,
  },{
    path: '/questions/clone',
    exact: true,
    name: 'Clone',
    component: Clone,
    protect: true,
  },
];

export const questionsNav = [
  {
    component: CNavGroup,
    name: 'Questions',
    to: '/questions',
    icon: cilCommentBubbleQuestion,
    items: [
      {
        component: CNavItem,
        name: 'Search',
        to: '/questions/search',
        icon: cilSearch,
      },
      {
        component: CNavGroup,
        name: 'Lesson Q&A',
        to: '/questions/lesson-qa',
        icon: collectionMapping[COLLECTION_LESSON_QA].icon,
        color: collectionMapping[COLLECTION_LESSON_QA].color,
        items: [
          {
            component: CNavItem,
            name: 'Overview',
            to: '/questions/lesson-qa/overview',
          },
          {
            component: CNavItem,
            name: 'Create',
            to: '/questions/lesson-qa/create',
          },
          {
            component: CNavItem,
            name: 'Export',
            to: '/questions/lesson-qa/export',
          },
        ],
      },
      {
        component: CNavGroup,
        name: 'Practice Exam',
        to: '/questions/practice-exam',
        icon: collectionMapping[COLLECTION_PRACTICE_EXAM].icon,
        color: collectionMapping[COLLECTION_PRACTICE_EXAM].color,
        items: [
          {
            component: CNavItem,
            name: 'Overview',
            to: '/questions/practice-exam/overview',
          },
          {
            component: CNavItem,
            name: 'Create',
            to: '/questions/practice-exam/create',
          },
          {
            component: CNavItem,
            name: 'Export',
            to: '/questions/practice-exam/export',
          },
        ],
      },
      {
        component: CNavGroup,
        name: 'Targeted Review',
        to: '/questions/targeted-review',
        icon: collectionMapping[COLLECTION_TARGETED_REVIEW].icon,
        color: collectionMapping[COLLECTION_TARGETED_REVIEW].color,
        items: [
          {
            component: CNavItem,
            name: 'Overview',
            to: '/questions/targeted-review/overview',
          },
          {
            component: CNavItem,
            name: 'Create',
            to: '/questions/targeted-review/create',
          },
          {
            component: CNavItem,
            name: 'Export',
            to: '/questions/targeted-review/export',
          },
        ],
      },
      {
        component: CNavGroup,
        name: 'True/False',
        to: '/questions/true-false',
        icon: collectionMapping[COLLECTION_TRUE_FALSE].icon,
        color: collectionMapping[COLLECTION_TRUE_FALSE].color,
        items: [
          {
            component: CNavItem,
            name: 'Overview',
            to: '/questions/true-false/overview',
          },
          {
            component: CNavItem,
            name: 'Create',
            to: '/questions/true-false/create',
          },
          {
            component: CNavItem,
            name: 'Export',
            to: '/questions/true-false/export',
          },
        ],
      },
      {
        component: CNavGroup,
        name: 'Learning Feature',
        to: '/questions/learning-feature',
        icon: collectionMapping[COLLECTION_LEARNING_FEATURE].icon,
        color: collectionMapping[COLLECTION_LEARNING_FEATURE].color,
        items: [
          {
            component: CNavItem,
            name: 'Overview',
            to: '/questions/learning-feature/overview',
          },
          {
            component: CNavItem,
            name: 'Create',
            to: '/questions/learning-feature/create',
          },
          {
            component: CNavItem,
            name: 'Export',
            to: '/questions/learning-feature/export',
          },
        ],
      },
      {
        component: CNavItem,
        name: 'Exports',
        to: '/questions/exports',
        icon: cilFileArchive,
      }, {
        component: CNavItem,
        name: 'Clone',
        to: '/questions/clone',
        icon: cilClone,
      },
    ],
  },
];
