import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Loading from './components/Loading';
import RequireAuth from './components/PrivateRoute';
import { firebaseAuthStateChanged } from './firebase/auth';
import routes from './routes';
import './scss/style.scss';
import { signOutThunk } from './thunks/authentication';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const Login = React.lazy(() => import('./pages/basic/Login'));
const TheLayout = React.lazy(() => import('./layout/AppLayout'));

interface AppProps {
  signOut: () => {};
}

const App: FC<AppProps> = ({ signOut }) => {
  useEffect(() => {
    const onAuthChange = firebaseAuthStateChanged((user: any) => {
      // console.log('AuthChange Detected', user);
      if (user) {
      } else {
        signOut();
      }
    });
    onAuthChange();
    return () => onAuthChange();
  }, [signOut]);

  return (
    <>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              element={
                <RequireAuth>
                  <TheLayout />
                </RequireAuth>
              }
            >
              {routes.map((route, idx) => {
                if (route.component) {
                  return <Route key={idx} path={route.path} element={<route.component {...route.componentProps} />} />;
                }

                return route.component && <Route key={idx} path={route.path} element={<route.component />} />;
              })}
              <Route path="/" element={<Navigate to="/dashboard" />} />
            </Route>
          </Routes>
        </React.Suspense>
        <Loading />
      </BrowserRouter>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  // signIn: (email: string, password: string) => dispatch(signInThunk(email, password)),
  signOut: () => dispatch(signOutThunk()),
});

export default connect(null, mapDispatchToProps)(App);
