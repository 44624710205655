import { LOADING, SET_GLOBAL } from '../../constants';
import { GlobalActionTypes } from '../actions';

export interface GlobalState {
  isLoading: boolean;
  loadingMessage: string;
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
}

const initialState: GlobalState = {
  isLoading: false,
  loadingMessage: 'Loading...',
  sidebarShow: true,
  sidebarUnfoldable: true,
};

const globalReducer = (state = initialState, action: GlobalActionTypes): GlobalState => {
  switch (action.type) {
    case LOADING: {
      return {
        ...state,
        isLoading: action.trueFalse,
        loadingMessage: action.message,
      };
    }
    case SET_GLOBAL: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default globalReducer;
