import { clearUserAction, isLoadingAction, signInAction, signOutAction, updateUserAction } from '../store/actions';
import { firebaseSignIn, firebaseSignOut } from '../firebase/auth';
import { getClaims, getUser } from '../firebase/models/user';
import { sendError } from '../helpers';

export const SignInThunk = (email: string, password: string) => {

  return async (dispatch: any) => {
    try {
      dispatch(isLoadingAction(true, 'Authenticating...'));

      const authResponse = await firebaseSignIn(email, password)
        .then(async ({ user, error }) => {
          if (error) {
            return { error };
          }
          if (user) {
            const userData: any = await getUser(user.uid);
            const userClaims: any = await getClaims();
            return {
              id: user.uid,
              email: user.email,
              name: user.displayName,
              bookmarks: userData.bookmarks || {},
              claims: userClaims,
            };
          }
          return {};
        })
        .catch((error: any) => error);

      if (authResponse && authResponse.error) {
        dispatch(isLoadingAction(false));
        return; //Toast.add({ color: 'danger', message: authResponse.error });
      }

      if (authResponse && authResponse.claims) {
        await dispatch(signInAction(authResponse.id, authResponse.claims.admin, authResponse.claims.permissions));
      }

      if (authResponse && (authResponse.name || authResponse.bookmarks)) {
        await dispatch(
          updateUserAction({
            name: authResponse?.name,
            email: authResponse?.email,
            bookmarks: authResponse?.bookmarks,
          }),
        );
      }

      dispatch(isLoadingAction(false));
    } catch (error) {
      sendError(error);
      return { error: true };
    }

    return { success: true };
  };
};

export const signOutThunk = () => {
  return async (dispatch: any) => {
    try {
      dispatch(isLoadingAction(true, 'Signing out...'));
      const authStatus = await firebaseSignOut();

      if (authStatus) {
        dispatch(signOutAction());
        dispatch(clearUserAction());
      }

      dispatch(isLoadingAction(false));
      return authStatus;
    } catch (error) {
      sendError(error);
    }
    return { error: true };
  };
};
