import { lazy } from 'react';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { cilIdBadge, cilBellRing } from '@coreui/icons-pro';

import { RouteProps } from '../index';

const DigitalIdNotifications = lazy(() => import('../../pages/digital-id/Notifications'));

export const digitalIdRoutes: RouteProps[] = [
  { path: '/digital-id', exact: true, name: 'Digital ID', protect: true, permissions: ['did:view'] },
  {
    path: '/digital-id/notifications',
    name: 'Notifications',
    component: DigitalIdNotifications,
    protect: true,
    permissions: ['did:notify'],
  },
];

export const didNav = [
  {
    component: CNavGroup,
    name: 'Digital ID',
    to: '/digital-id',
    icon: cilIdBadge,
    items: [
      {
        component: CNavItem,
        name: 'Notifications',
        to: '/digital-id/notifications',
        icon: cilBellRing,
      },
    ],
  },
];
