import { SIGN_IN, SIGN_OUT } from '../../constants';
import { AuthActionTypes } from '../actions';

export interface AuthState {
  signedIn: boolean;
  id: string;
  claims: {
    admin: boolean;
    permissions: string[];
  };
}

const initialState: AuthState = {
  signedIn: false,
  id: '',
  claims: {
    admin: false,
    permissions: [],
  },
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case SIGN_IN: {
      return {
        ...state,
        signedIn: true,
        id: action.id,
        claims: {
          admin: action.admin,
          permissions: action.permissions,
        },
      };
    }
    case SIGN_OUT: {
      return {
        ...state,
        signedIn: false,
        id: '',
        claims: { admin: false, permissions: [] },
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
