export const quillModule = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ 'script': 'sub'}, { 'script': 'super' }],
    ['clean'],
  ],
};
export const quillModuleWithImage = {
  toolbar: [
    ...quillModule.toolbar,
    ['image'],
  ],
};
