import React, { FC } from 'react';
import { connect } from 'react-redux';
import { GlobalState, ReducerState } from '../store/reducers';
import { CSpinner } from '@coreui/react-pro';

import './loading.css';

interface LoadingProps {
  global: GlobalState;
}

const Loading: FC<LoadingProps> = ({ global }) => {
  if (global.isLoading) {
    return (
      <div className={'mask'}>
        <div className={'spinner-position'}>
          <CSpinner />
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state: ReducerState) => ({ global: state.global });

export default connect(mapStateToProps)(Loading);
