import React, { createContext, FC, ReactElement, useContext, useState } from 'react';
import { CToast, CToastBody, CToastClose, CToaster, CToastHeader } from '@coreui/react-pro';
import ReactHtmlParser from 'react-html-parser';

type Toast = {
  color?: string;
  message: string;
  closeable?: boolean;
  title?: string;
  duration?: number;
};

interface ToastContextInterface {
  add: (toast: Toast) => void;
}

const ToastContext = createContext<ToastContextInterface>({ add: () => {} });

export default ToastContext;

export const ToastContextProvider: FC = ({ children }) => {
  const [toast, setToast] = useState<ReactElement>(<></>);

  const addToast = (toast: Toast) => {
    const { color, message, title, duration, closeable } = toast;
    setToast(
      <CToast
        color={color ? color : ''}
        className={color ? 'text-light align-items-center' : 'align-items-center'}
        delay={duration ? duration : 5000}
      >
        {title && (
          <CToastHeader className="text-bold">
            {title}
          </CToastHeader>
        )}
        <div className="d-flex">
          <CToastBody>{ReactHtmlParser(message)}</CToastBody>
          {closeable && <CToastClose className="me-2 m-auto" white />}
        </div>
      </CToast>,
    );
  };

  return (
    <ToastContext.Provider value={{ add: addToast }}>
      {children}
      <CToaster push={toast} placement="top-end" />
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
