import { format } from 'date-fns';
import { sendError } from './logging';

export const prettyDate = (value: any) => {
  let date = value;
  if (!(value instanceof Date)) {
    try {
      date = value.toDate();
    } catch (e) {
      sendError(e);
    }
  }
  try {
    return format(date, 'MMMM d, yyyy, HH:mm');
  } catch (e) {
    return '?';
  }
};
