import logdna from '@logdna/logger';
import { appConfig } from '../config';

export const logger = logdna.createLogger(appConfig.LOGDNA_INGESTION_KEY, {
  app: 'elite-headquarters',
  hostname: 'elite-headquarters',
  tags: ['hq'],
});

const createLogOptions = (level: string, meta: any = {}) => {
  return { level, meta };
};

export const sendError = (error: any) => {
  logger.log(error, createLogOptions('error'));
  // if (process.env.DEBUG) {
  console.error(error);
  // }
  return error;
};

export const sendInfo = (message: string, meta?: any) => {
  logger.log(message, createLogOptions('info', meta));
  // if (process.env.DEBUG) {
  console.info({ message, ...meta });
  // }
  return { message, ...meta };
};

export const sendDebug = (debug: any) => {
  logger.log(debug, createLogOptions('debug'));
  // if (process.env.DEBUG) {
  console.debug(debug);
  // }
  return debug;
};
