// @ts-ignore
import packageJson from '../../package.json';

export const firebaseConfig = {
  apiKey: 'AIzaSyAKncBLIq1exQGdF09jGdvRbsixFKNIY60',
  authDomain: 'est-databases.firebaseapp.com',
  databaseURL: 'https://est-databases.firebaseio.com',
  projectId: 'est-databases',
  storageBucket: 'est-databases.appspot.com',
  messagingSenderId: '966982689956',
  appId: '1:966982689956:web:c38be54ab51f04030a7231',
};

export const appConfig = {
  LOGDNA_INGESTION_KEY: 'bc2246bca2826a239ecea34f4ab95267',
  APP_VERSION: packageJson.version,
};
