import jsonExport from 'jsonexport/dist';

export function exportCSVFile(items: any[], fileTitle: string) {
  const exportedFilename = fileTitle + '.csv' || 'export.csv';

  jsonExport(items, (err: any, csv: any) => {
    if (err) return console.error(err);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  });
}
