import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCardHeader,
  CCol,
  CContainer,
  CFormFloating,
  CFormInput,
  CFormLabel,
  CRow,
  CSpinner
} from '@coreui/react-pro';
import React, { FC, useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { deleteElfUser, getElfUser } from '../../firebase/models/elf';
import { useToast } from '../../providers/Toast';

const Users: FC = () => {
  const Toast = useToast();

  const [loading] = useState<boolean>(false);
  const [loadingJson, setLoadingJson] = useState<boolean>(true);

  const [json, setJson] = useState<any>({});
  // const [userJson, setUserJson] = useState<any>();

  const [canSave, setCanSave] = useState<boolean>(false);

  const [userID, setUserID] = useState<string>('');
  const [username, setUsername] = useState<string>('');

  useEffect(() => {
    getElfUser({ id: '13259' }).then((r: any) => loadUserData(r[0]));
  }, []);

  const loadUserData = (userObject: any) => {
    setLoadingJson(true);
    setJson(userObject);
    setLoadingJson(false);
  };

  const onUserDataChange = ({ updated_src }: any) => {
    if (updated_src) {
      setJson(updated_src);
      setCanSave(true);
    } else {
      setCanSave(false);
    }
  };

  //const updateUserData = (userData: any) => {
  //      setJson(jsObject);
  //};

  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(json))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${json.id}.json`;

    link.click();
  };

  const searchUsers = () => {
    const query: { id?: string; username?: string } = {};
    if (userID.length > 0) {
      query.id = userID;
    } else if (username.length > 0) {
      query.username = username;
    }
    getElfUser(query).then((r: any) => loadUserData(r[0]));
  };

  const deleteUser = async () => {
    const { id } = json;
    console.log('delete', id);
    if (id) {
      const { _writeTime }: any = await deleteElfUser(id);
      if (_writeTime) {
        Toast.add({ message: `successfully remove the user profile ${id}`, color: 'success' });
        setUsername('');
        setUserID('');
        setJson({});
      }
    }
  };

  // @ts-ignore
  return (
    <CContainer>
      <CRow>
        <CCol sm={3}>
          <CCard className="mb-4">
            <CCardHeader>Search</CCardHeader>
            <CCardBody>
              {!username && (
                <CFormFloating className="mb-3">
                  <CFormInput
                    type="text"
                    id="idInput"
                    placeholder="ID Number"
                    onChange={({ target }: any) => {
                      setUserID(target.value);
                      setUsername('');
                    }}
                  />
                  <CFormLabel htmlFor="idInput">ID Number</CFormLabel>
                </CFormFloating>
              )}
              {!userID && (
                <CFormFloating className="mb-3">
                  <CFormInput
                    type="text"
                    id="usernameInput"
                    placeholder="Username"
                    onChange={({ target }: any) => {
                      setUsername(target.value);
                      setUserID('');
                    }}
                  />
                  <CFormLabel htmlFor="usernameInput">Username</CFormLabel>
                </CFormFloating>
              )}
              <CButton onClick={searchUsers}>Search</CButton>
              {!loading && loading ? <CSpinner onClick={() => setJson({ test: true })} /> : <></>}
            </CCardBody>
          </CCard>
        </CCol>
        <CCol sm={9}>
          {!loading && (
            <CCard className="mb-4">
              <CCardBody>
                <CCardBody>
                  {loadingJson ? (
                    <CSpinner />
                  ) : (
                    <ReactJson
                      src={json}
                      name={false}
                      collapsed={1}
                      onAdd={(i) => onUserDataChange(i)}
                      onEdit={(i) => onUserDataChange(i)}
                      onDelete={(i) => onUserDataChange(i)}
                    />
                  )}
                </CCardBody>
                <CCardFooter>
                  <CRow>
                    <CCol>
                      <CButton
                        color="primary"
                        disabled={!canSave}
                        className="me-md-2"
                        onChange={() => console.log('clicked')}
                      >
                        Save
                      </CButton>
                      {canSave && (
                        <CButton color="secondary" onClick={() => searchUsers}>
                          Reset Changes
                        </CButton>
                      )}
                    </CCol>
                    <CCol>
                      <CButton color="warning" variant="outline" onClick={() => exportData()}>
                        download
                      </CButton>
                      <CButton color="danger" variant="outline" onClick={() => deleteUser()}>
                        Delete
                      </CButton>
                    </CCol>
                  </CRow>
                </CCardFooter>
              </CCardBody>
            </CCard>
          )}
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default Users;
