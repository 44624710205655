import { onAuthStateChanged } from 'firebase/auth';
import React from 'react';
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './App';
import { icons } from './assets/icons';
import { auth } from './firebase/auth';
import './polyfill';
import * as serviceWorker from './serviceWorker';
import { store } from './store';

React.icons = icons;

let ReactAppInit: any = false;

onAuthStateChanged(auth, () => {
  if (!ReactAppInit) {
    ReactAppInit = ReactDOM.render(
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>,
      document.getElementById('root'),
    );
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
