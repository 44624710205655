import { SIGN_IN, SIGN_OUT } from '../../constants';

interface SignInProps {
  type: typeof SIGN_IN;
  id: string;
  admin: boolean;
  permissions: string[];
}

interface SignOutProps {
  type: typeof SIGN_OUT;
}

export type AuthActionTypes = SignInProps | SignOutProps;

export const signInAction = (id: string, admin: boolean, permissions: string[]): AuthActionTypes => ({
  type: SIGN_IN,
  id,
  admin,
  permissions,
});

export const signOutAction = (): AuthActionTypes => ({
  type: SIGN_OUT,
});
