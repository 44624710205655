import { LOADING, SET_GLOBAL } from '../../constants';

interface LoadingActionProps {
  type: typeof LOADING;
  trueFalse: boolean;
  message: string;
}

interface SetGlobalActionProps {
  type: typeof SET_GLOBAL;
  payload: any;
}

export type GlobalActionTypes = LoadingActionProps | SetGlobalActionProps;

export const isLoadingAction = (trueFalse: boolean, message: string = 'Loading...'): GlobalActionTypes => ({
  type: LOADING,
  trueFalse,
  message: trueFalse ? message : '',
});

export const setGlobalAction = (payload: any): GlobalActionTypes => ({
  type: SET_GLOBAL,
  payload,
});
