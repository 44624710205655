import { Option } from '@coreui/react-pro/dist/components/multi-select/CMultiSelect';
import { includes } from 'lodash';

const camelCaseString = (str: string) =>
  str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(' ');

export const makeOptionsFromString = (item: string) => [
  {
    value: item,
    label: camelCaseString(item),
  },
];

export const makeOptionsFromArray = (arr: string[]) =>
  arr.map((item) => ({
    value: item,
    label: camelCaseString(item),
  }));

export const makeCoreOptionsFromArray = (arr: string[]) =>
  arr.map((item) => ({
    value: item,
    text: camelCaseString(item),
  }));

export const makeOptionsFromObject = (obj: { [k: string]: number }) =>
  Object.entries(obj).map(([key]) => ({
    value: key,
    label: camelCaseString(key),
  }));

export const makeCoreOptionsFromObject = (obj: { [key: string]: number }): Option[] => {
  const optionArray = [];
  for (const key of Object.keys(obj)) {
    optionArray.push({
      value: key,
      text: key,
    });
  }
  return optionArray as Option[];
};

export const mergeOptionsWithSelected = (options: Option[], selected: string[] = []) => {
  // TODO: simplify
  const temp = options.map((option) => (includes(selected, option.value) ? { ...option, selected: true } : option));
  return temp;
};
