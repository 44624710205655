import Cookies from 'js-cookie';

import { auth } from '../auth';
import { getDocument, saveDocument } from '../firestore';
import { Bookmark, User } from '../../types';
import { COLLECTION_USERS } from '../../constants';

const getUser = async (id: string) => (await getDocument(COLLECTION_USERS, id)) as User;

const saveUser = async (id: string, data: User) => saveDocument(COLLECTION_USERS, data, id);

const addBookmark = async (bookmark: Bookmark) => {
  if (auth.currentUser) {
    const userData = await getUser(auth.currentUser.uid);
    if (userData) {
      if (!userData.bookmarks) {
        userData.bookmarks = {};
      }
      userData.bookmarks[bookmark.id] = bookmark;
      await saveUser(auth.currentUser.uid, userData);
      return userData.bookmarks;
    }
  }
  return {};
};

const removeBookmark = async (id: string) => {
  if (auth.currentUser) {
    const userData = await getUser(auth.currentUser.uid);
    delete userData.bookmarks[id];
    await saveUser(auth.currentUser.uid, userData);
    return userData.bookmarks;
  }
  return {};
};

const getBookmarks = async () => {
  if (auth.currentUser) {
    const userData = await getUser(auth.currentUser.uid);
    if (userData) {
      return userData.bookmarks;
    }
  }
  return {};
};

const getClaims = async () => {
  if (auth.currentUser) {
    const { admin = false, permissions = [] } = await auth.currentUser.getIdTokenResult().then((response) => {
      return response.claims;
    });
    Cookies.set('customClaims', { admin, permissions });
    return { admin, permissions };
  }
};

export { getUser, saveUser, addBookmark, removeBookmark, getBookmarks, getClaims };
