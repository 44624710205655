import { lazy } from 'react';

import { RouteProps } from '../index';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { cilCreditCard, cilEducation } from '@coreui/icons-pro';

const Stripe = lazy(() => import('../../pages/lms/Stripe'));

export const lmsRoutes: RouteProps[] = [
  { path: '/lms', exact: true, name: 'LMS', protect: true, permissions: ['lms:view'] },
  {
    path: '/lms/stripe',
    exact: true,
    name: 'Stripe',
    component: Stripe,
    protect: true,
    permissions: ['lms:view', 'lms:stripe'],
  },
];

export const lmsNav = [
  {
    component: CNavGroup,
    name: 'LMS',
    to: '/lms',
    icon: cilEducation,
    items: [
      {
        component: CNavItem,
        name: 'Stripe',
        to: '/lms/stripe',
        icon: cilCreditCard,
      },
    ],
  },
];
