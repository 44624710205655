import { RouteProps } from '../index';
import { lazy } from 'react';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { cilAsterisk, cilGroup, cilTrash } from '@coreui/icons-pro';

const Trash = lazy(() => import('../../pages/tools/Trash'));

export const toolsRoutes: RouteProps[] = [
  { path: '/tools', name: 'Tools', protect: true, permissions: ['trash:view'] },
  { path: '/tools/trash', name: 'Trash', component: Trash, protect: true, permissions: ['trash:view'] },
];

export const toolsNav = [
  {
    component: CNavGroup,
    name: 'Tools',
    to: '/tools',
    icon: cilAsterisk,
    items: [
      {
        component: CNavItem,
        name: 'Users',
        to: '/users',
        icon: cilGroup,
      },
      {
        component: CNavItem,
        name: 'Trash',
        to: '/tools/trash',
        icon: cilTrash,
      },
    ],
  },
];
