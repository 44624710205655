import axios from 'axios';
import { auth } from '../firebase/auth';

const service = axios.create({
  timeout: 60000,
});

// Request interceptors
service.interceptors.request.use(
  async (config) => {
    if (auth.currentUser && config) {
      const token = await auth.currentUser.getIdToken(true);
      if (token && config.headers) {
        config.headers.Authorization = token; // make each request include custom token header
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// response interceptor
service.interceptors.response.use(
  (response: any) => {
    return Promise.resolve(response.data);
  },
  (error: any) => {
    console.error(error.message);
    return Promise.reject(error);
  },
);

export default service;
