import React, { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { AuthState, ReducerState } from '../store/reducers';

//interface PrivateRouteProps {
//  auth: AuthState;
//  element: React.CElement<any, any>;
//  path: string;
//  exact?: boolean;
//}

interface RequireAuthProps {
  auth: AuthState;
  children: ReactElement;
}

export const RequireAuth: FC<RequireAuthProps> = ({ children, auth }) => {
  let location = useLocation();

  if (!auth.signedIn) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

// const PrivateRoute: FC<PrivateRouteProps> = ({ auth, path, exact, element }) => {
//   const location = useLocation();
//   // return     <Navigate to={'/login'} state={{ from: location.pathname }} />
//
//   return auth.signedIn ? (
//     <Route path={path} element={element} />
//   ) : (
//     <Navigate to={'/login'} state={{ from: location.pathname }} />
//   );
// };

const mapStateToProps = (state: ReducerState) => ({ auth: state.auth });

export default connect(mapStateToProps)(RequireAuth);
