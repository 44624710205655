import {
  browserLocalPersistence,
  browserPopupRedirectResolver,
  initializeAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut
} from 'firebase/auth';
import { sendError } from '../helpers';
import { firebaseApp } from './firebase';


// export const auth = getAuth(firebaseApp);

export const auth = initializeAuth(firebaseApp, {
  persistence: browserLocalPersistence,
  popupRedirectResolver: browserPopupRedirectResolver,
});

// if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_EMULATOR) {
//   connectAuthEmulator(auth, 'http://localhost:9099');
// }

export const firebaseSignIn = (email: string, password: string) => {
  // return setPersistence(auth, browserLocalPersistence)
  //   .then(() => {
  return signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      return { ...userCredential, error: false };
    })
    .catch((error) => ({
      error: 'Invalid username or password',
      user: null,
    }));
  // })
  // .catch((error) => ({
  //   error: 'Problem with authentication persistence.',
  //   user: null,
  // }));
};

export const firebaseSignOut = async () =>
  signOut(auth)
    .then(() => ({
      success: true,
    }))
    .catch((error) => sendError(error));

export const firebaseAuthStateChanged = (callback: any) => onAuthStateChanged(auth, callback);

export const passwordResetEmail = (email: string) =>
  sendPasswordResetEmail(auth, email)
    .then(() => ({ success: 'Password reset email sent' }))
    .catch((error) => sendError(error) && { error: error.message });

// createUserWithEmailAndPassword(auth, email, password)
//   .then((userCredential) => {
//     // Signed in
//     const user = userCredential.user;
//     // ...
//   })
//   .catch((error) => {
//     const errorCode = error.code;
//     const errorMessage = error.message;
//     // ..
//   });
//
//
auth.onAuthStateChanged(async (user: any) => {
  if (user) {
    // await UserModule.setUser(user);
    // if (Cookies.getJSON('customClaims')) {
    //   await UserModule.SetClaims(Cookies.getJSON('customClaims'));
    // } else {
    //   const userClaims: any = await getClaims();
    //   await UserModule.SetClaims(userClaims);
    // }
    // const bookmarks = await getBookmarks();
    // UserModule.SetBookmarks(bookmarks);
  } else {
    // if (UserModule.authenticated) {
    //   await UserModule.Logout();
    // }
  }
});
