import { lazy } from 'react';

import { RouteProps } from '../index';

const UserList = lazy(() => import('../../pages/users/List'));
const UserForm = lazy(() => import('../../pages/users/UserForm'));

export const usersRoutes: RouteProps[] = [
  { path: '/users', exact: true, name: 'Users', component: UserList, protect: true, permissions: [] },
  { path: '/users/create', name: 'Add', component: UserForm, protect: true, permissions: [] },
  { path: '/users/edit', name: 'Edit', protect: true },
  { path: '/users/edit/:id', name: 'Edit', component: UserForm, protect: true, permissions: [] },
];
