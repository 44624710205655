import { ADD_BOOKMARK, CLEAR_USER, REMOVE_BOOKMARK, UPDATE_USER, CollectionTypes } from '../../constants';

interface UpdateUserProps {
  type: typeof UPDATE_USER;
  payload: any;
}

interface ClearUserProps {
  type: typeof CLEAR_USER;
}

interface AddBookmarkProps {
  type: typeof ADD_BOOKMARK;
  id: string;
  collection: CollectionTypes;
  question: string;
}

interface RemoveBookmarkProps {
  type: typeof REMOVE_BOOKMARK;
  id: string;
}

export type UserActionTypes = UpdateUserProps | ClearUserProps | AddBookmarkProps | RemoveBookmarkProps;

export const updateUserAction = (payload: any): UserActionTypes => ({
  type: UPDATE_USER,
  payload,
});

export const clearUserAction = (): UserActionTypes => ({
  type: CLEAR_USER,
});

export const addBookmarkAction = (id: string, collection: CollectionTypes, question: string): UserActionTypes => ({
  type: ADD_BOOKMARK,
  id,
  collection,
  question,
});

export const removeBookmarkAction = (id: string): UserActionTypes => ({
  type: REMOVE_BOOKMARK,
  id,
});
