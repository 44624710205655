import { cilChevronBottom, cilChevronTop } from '@coreui/icons-pro';
import CIcon from '@coreui/icons-react';
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CCollapse,
  CFormCheck,
  CRow,
  CSpinner
} from '@coreui/react-pro';
import { clone } from 'lodash';
import React, { FC, useEffect, useState } from 'react';
import { collectionMapping } from '../../constants';
import { exportHelper, searchHelper } from '../../helpers';
import { Facet } from '../../types';

export interface ActiveFacets {
  [attribute: string]: {
    [item: string]: boolean;
  };
}

interface FacetsProps {
  facets: Facet[];
  existingRefinements?: any;
  loading?: boolean;
  forceColor?: string;
  forExport?: boolean;
  onFacetChange?: (facets: ActiveFacets) => any;
}

const Facets: FC<FacetsProps> = ({ facets, loading, existingRefinements, forceColor, forExport, onFacetChange }) => {
  const searchTool = forExport ? exportHelper : searchHelper;

  const [activeAreas, setActiveAreas] = useState<{ [area: string]: boolean }>({ collection: true, rank: true });
  const [activeFacets, setActiveFacets] = useState<ActiveFacets>({});

  const toggleFacet = (attribute: string, item: string) => {
    const newActiveFacets = clone(activeFacets);
    if (attribute in newActiveFacets && item in newActiveFacets[attribute]) {
      delete newActiveFacets[attribute][item];
    } else {
      if (!(attribute in newActiveFacets)) {
        newActiveFacets[attribute] = {};
      }
      newActiveFacets[attribute][item] = true;
    }
    setActiveFacets(newActiveFacets);
    searchTool.toggleFacetRefinement(attribute, item).search();

    // const readableFacets: { [attribute: string]: string[] } = {};
    // for (const [attr, items] of Object.entries(newActiveFacets)) {
    //   readableFacets[attr] = Object.keys(items);
    // }
    onFacetChange && onFacetChange(newActiveFacets);
  };

  const toggleArea = (name: string) => {
    setActiveAreas({
      ...activeAreas,
      [name]: !activeAreas[name],
    });
  };

  const clearRefinements = () => {
    setActiveFacets({});
    searchTool.clearRefinements().search();
  };

  useEffect(() => {
    const newActiveFacets: { [attribute: string]: { [item: string]: boolean } } = {};
    if (existingRefinements) {
      for (const [attribute, items] of Object.entries(existingRefinements as { [attribute: string]: string[] })) {
        newActiveFacets[attribute] = {};
        for (const item of items) {
          newActiveFacets[attribute][item] = true;
        }
      }
      setActiveFacets(newActiveFacets);
    }
  }, [existingRefinements]);

  return (
    <div>
      {loading ? (
        <CCardBody>
          <CSpinner />
        </CCardBody>
      ) : (
        <>
          {facets.map(({ name, data }, index) => (
            <CCard key={index} className="mb-1">
              <CCardHeader role="button" className="text-capitalize pointer" onClick={() => toggleArea(name)}>
                {name}
                <CIcon icon={activeAreas[name] ? cilChevronTop : cilChevronBottom} className="float-end mt-1" />
              </CCardHeader>
              <CCollapse visible={activeAreas[name] || false}>
                <CCardBody className="text-capitalize">
                  {Object.entries(data).map(([facet, count]) => (
                    <CRow key={facet}>
                      <CCol>
                        <CFormCheck
                          id={`${facet}-checkbox`}
                          readOnly
                          label={
                            <>
                              {facet}
                              <CBadge
                                color={forceColor ? forceColor : collectionMapping[facet]?.color || 'secondary'}
                                shape="rounded-pill"
                                className="ms-2"
                              >
                                {count}
                              </CBadge>
                            </>
                          }
                          checked={activeFacets[name] && activeFacets[name][facet] ? true : false}
                          onClick={() => toggleFacet(name, facet)}
                        />
                      </CCol>
                    </CRow>
                  ))}
                </CCardBody>
              </CCollapse>
            </CCard>
          ))}
          {!forExport && (
            <div className="d-grid gap-2">
              <CButton onClick={() => clearRefinements()} color="secondary">
                Clear all Facets
              </CButton>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Facets;
