import { lazy } from 'react';

import {digitalIdRoutes, elfRoutes, lmsRoutes, questionsRoutes, toolsRoutes, usersRoutes} from './areas';
import { claimOptionsValues } from '../config/users/claimOptions';
import { userRoutes } from './areas/user';

const Login = lazy(() => import('../pages/basic/Login'));
const Dashboard = lazy(() => import('../pages/dashboard/Dashboard'));
const DailyQuestion = lazy(() => import('../pages/daily-question/DailyQuestion'));

export type RouteProps = {
  path: string;
  name: string;
  exact?: boolean;
  component?: any;
  componentProps?: any;
  protect?: boolean;
  permissions?: claimOptionsValues[];
};

const routes: RouteProps[] = [
  { path: '/', exact: true, name: 'Home', protect: true },
  { path: '/login', exact: true, name: 'Login', component: Login, protect: false },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard, protect: true, permissions: [] },
  {
    path: '/daily-question',
    name: 'Daily Question',
    component: DailyQuestion,
    protect: true,
    permissions: ['daily:view'],
  },
  ...digitalIdRoutes,
  ...elfRoutes,
  ...lmsRoutes,
  ...questionsRoutes,
  ...toolsRoutes,
  ...userRoutes,
  ...usersRoutes,
];

export default routes;
