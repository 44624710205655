const claimOptions = [
  'dashboard:view',
  'daily:view',
  'daily:reset',
  'elf:view',
  'elf:configure',
  'elf:builder',
  'elf:review',
  'elf:users',
  'lms:view',
  'lms:stripe',
  'lms:studens',
  'lms:export',
  'did:view',
  'did:notify',
  'questions:view',
  'questions:search',
  'questions:view',
  'questions:create',
  'questions:update',
  'questions:delete',
  'questions:export',
  'trash:view',
  'trash:restore',
  'trash:delete',
  'user:view',
  'user:create',
  'user:update',
  'user:delete',
  'bookmarks:view',
  'exports:view',
  'maintenance:view',
] as const;

export type claimOptionsValues = typeof claimOptions[number];

// const claimOptions: { [type: string]: { value: string; label: string }[] } = {};

export default claimOptions;
