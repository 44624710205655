import { cilBook, cilChartPie, cilCheckCircle, cilPuzzle, cilTarget } from '@coreui/icons-pro';

export const COLLECTION_DAILY_QUESTION = 'DailyQuestion';
export const COLLECTION_EXPORTS = 'Exports';
export const COLLECTION_LEARNING_FEATURE = 'LearningFeature';
export const COLLECTION_LEARNING_FEATURE_EXAMS = 'LearningFeatureExams';
export const COLLECTION_LESSON_QA = 'LessonQA';
export const COLLECTION_OVERVIEW = 'Overview';
export const COLLECTION_PRACTICE_EXAM = 'PracticeExam';
export const COLLECTION_TARGETED_REVIEW = 'TargetedReview';
export const COLLECTION_TRASH = 'Trash';
export const COLLECTION_TRUE_FALSE = 'TrueFalse';
export const COLLECTION_USERS = 'Users';

export const collectionMapping: {
  [key: string]: { name: string; color: string; hex: string; icon?: typeof cilPuzzle; baseEdit: string };
} = {
  [COLLECTION_LEARNING_FEATURE]: {
    name: 'Learning Feature',
    color: 'elf',
    hex: 'purple',
    icon: cilPuzzle,
    baseEdit: '/questions/learning-feature/edit',
  },
  [COLLECTION_PRACTICE_EXAM]: {
    name: 'Practice Exam',
    color: 'danger',
    hex: '#e55353',
    icon: cilChartPie,
    baseEdit: '/questions/practice-exam/edit',
  },
  [COLLECTION_LESSON_QA]: {
    name: 'Lesson QA',
    color: 'info',
    hex: '#3399ff',
    icon: cilBook,
    baseEdit: '/questions/lesson-qa/edit',
  },
  [COLLECTION_TARGETED_REVIEW]: {
    name: 'Targeted Review',
    color: 'warning',
    hex: '#f9b115',
    icon: cilTarget,
    baseEdit: '/questions/targeted-review/edit',
  },
  [COLLECTION_TRUE_FALSE]: {
    name: 'True / False',
    color: 'success',
    hex: '#2eb85c',
    icon: cilCheckCircle,
    baseEdit: '/questions/true-false/edit',
  },
};

const collectionArray = [
  COLLECTION_DAILY_QUESTION,
  COLLECTION_EXPORTS,
  COLLECTION_LEARNING_FEATURE,
  COLLECTION_LEARNING_FEATURE_EXAMS,
  COLLECTION_LESSON_QA,
  COLLECTION_OVERVIEW,
  COLLECTION_PRACTICE_EXAM,
  COLLECTION_TARGETED_REVIEW,
  COLLECTION_TRASH,
  COLLECTION_TRUE_FALSE,
  COLLECTION_USERS,
] as const;

const collectionQuestionArray = [
  COLLECTION_LEARNING_FEATURE,
  COLLECTION_LESSON_QA,
  COLLECTION_PRACTICE_EXAM,
  COLLECTION_TARGETED_REVIEW,
  COLLECTION_TRUE_FALSE,
] as const;

export type CollectionTypes = typeof collectionArray[number];
export type QuestionTypes = typeof collectionQuestionArray[number];
