import {FC, useEffect, useState} from 'react';
import {CButton, CCard, CCardBody, CCardHeader, CCol, CRow, CSpinner, CTooltip} from '@coreui/react-pro';
import Facets, {ActiveFacets} from '../../components/search/Facets';
import {COLLECTION_TARGETED_REVIEW, COLLECTION_TRUE_FALSE, collectionMapping, CollectionTypes} from '../../constants';
import {exportHelper, sendError} from '../../helpers';
import {chain, clone, shuffle, zipObject} from 'lodash';
import {Question} from '../../types';
import {useToast} from '../../providers/Toast';
import {exportQuestionsPackage} from '../../firebase/models/export';
import CIcon from '@coreui/icons-react';
import {cilWarning} from '@coreui/icons-pro';

interface ExportProps {
  collection: CollectionTypes;
  withSections: boolean;
  allowShuffle: boolean;
  originalNumbering: boolean;
}

const Export: FC<ExportProps> = ({ collection, withSections, allowShuffle, originalNumbering }) => {
  const Toast = useToast();

  const [loading, setLoading] = useState<boolean>(true);
  const [exporting, setExporting] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [facets, setFacets] = useState<any[]>([]);
  const [activeFacets, setActiveFacets] = useState<ActiveFacets>({});
  const [sections, setSections] = useState<any[]>([]);
  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    exportHelper.on('result', async ({ results }) => {
      const { disjunctiveFacets, hits, nbHits } = results;
      setQuestions(hits);
      setFacets(disjunctiveFacets);
      setTotal(nbHits);
      if (withSections) {
        const newSections = await chain(results.hits)
          .groupBy('procedures')
          .toPairs()
          .map((pair) => zipObject(['name', 'questions'], pair))
          .sortBy(['name'])
          .value();
        setSections(newSections);
      } else if (collection === COLLECTION_TARGETED_REVIEW || COLLECTION_TRUE_FALSE) {
        // setQuestions(orderBy(questions, ['series', 'procedures']));
      }
      setLoading(false);
    });
    exportHelper.clearRefinements().addFacetRefinement('collection', collection).search();
    return () => {
      exportHelper.removeAllListeners('result');
    };
  }, [collection, withSections]);

  const shuffleQuestions = () => {
    if (withSections) {
      const newSections = clone(sections);
      for (const item of newSections) {
        if (item.questions) {
          item.questions = shuffle(item.questions);
        }
      }
      setSections(newSections);
    } else {
      setQuestions(shuffle(questions));
    }
  };

  const generateExport = async () => {
    setExporting(true);
    exportQuestionsPackage({
      collection: collection,
      questions: questions,
      sections: sections,
      meta: activeFacets,
    })
      .then((results:any) => {
        Toast.add({
          color: 'success',
          title: 'Successful Export',
          message: `<a href="${results.url}">Download</a> the exported package or view previous <a href="/questions/exports">exports</a>`,
          duration: 20000,
          closeable: true,
        });
      })
      .catch((error) => {
        Toast.add({
          color: 'danger',
          title: 'Error',
          message: `Something went wrong, please try again`,
          duration: 10000,
          closeable: true,
        });
        sendError(error);
      })
      .then(() => setExporting(false));
    return;
  };

  return (
    <CRow>
      <CCol xs={3}>
        <Facets
          facets={facets}
          forceColor={collectionMapping[collection].color}
          forExport
          loading={loading}
          onFacetChange={(newFacets) =>
            setActiveFacets({ rank: {}, series: {}, procedures: {}, tags: {}, ...newFacets })
          }
        />
      </CCol>
      <CCol>
        <CRow className="mb-4 align-items-end">
          <CCol xs="auto" className="align-content-center">
            <strong>Questions:</strong> {total}
            {total > 500 && (
              <CTooltip content={<>You cannot export {allowShuffle && 'or shuffle '}more than 500 questions.</>}>
                <CIcon icon={cilWarning} size="sm" className="text-danger ms-2 mb-1" />
              </CTooltip>
            )}
          </CCol>
          <CCol className="text-end">
            {allowShuffle && (
              <CButton
                disabled={total > 500}
                color="secondary"
                onClick={() => shuffleQuestions()}
                className="me-4 text-light"
              >
                Shuffle Questions
              </CButton>
            )}
            <CButton disabled={exporting || total > 500} onClick={() => generateExport()}>
              {exporting ? (
                <>
                  <CSpinner component="span" size="sm" aria-hidden="true" className="me-2" />
                  Exporting
                </>
              ) : (
                'Export'
              )}
            </CButton>
          </CCol>
        </CRow>
        {loading && <CSpinner />}

        {/* Without Sections */}
        {!withSections &&
          questions.map((question, index) => (
            <CCard className="mb-4" key={question.objectID}>
              <CCardBody>
                <CRow>
                  <CCol xs="auto">{originalNumbering ? question.id : index + 1}.</CCol>
                  <CCol>
                    <p>{question.query}</p>
                    {Array.isArray(question.answers) && (
                      <ol type="A">
                        {question.answers.map((answer, index) => (
                          <li key={index}>{answer.text}</li>
                        ))}
                      </ol>
                    )}
                    <CRow className="text-capitalize">
                      <CCol>
                        <span className="text-bold">Rank:</span> {question.rank}
                      </CCol>
                      <CCol>
                        <span className="text-bold">Series:</span> {question.series.join(', ')}
                      </CCol>
                      <CCol>
                        <span className="text-bold">Procedures:</span> {question.procedures.join(', ')}
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          ))}

        {/* With Sections */}
        {withSections &&
          sections.map((section, index) => (
            <CCard className="mb-3" key={index}>
              <CCardHeader>
                {section.name.length ? (
                  section.name
                ) : (
                  <>
                    <CIcon icon={cilWarning} size="sm" className="text-danger me-2" />
                    No Procedure Defined
                    <CIcon icon={cilWarning} size="sm" className="text-danger ms-2" />
                  </>
                )}
              </CCardHeader>
              <CCardBody>
                <ol>
                  {section.questions.map((sQuestion: Question) => (
                    <li key={sQuestion.objectID}>
                      <p>{sQuestion.query}</p>
                      {Array.isArray(sQuestion.answers) && (
                        <ol type="A" className="mb-2">
                          {sQuestion.answers.map((answer, index) => (
                            <li key={index}>{answer.text}</li>
                          ))}
                        </ol>
                      )}
                      <p>{sQuestion.feedback}</p>
                    </li>
                  ))}
                </ol>
              </CCardBody>
            </CCard>
          ))}
      </CCol>
    </CRow>
  );
};

export default Export;
