import { getFunction } from '../functions';
import { sendError } from '../../helpers/logging';
import { Stats } from '../../types';

const reIndexFunction = getFunction('reindexQuestions');
const getStatsFunction = getFunction('getStatistics');
const getNextNumberFunction = getFunction('getNextNumber');

const reindexQuestions = (data: any) =>
  reIndexFunction(data)
    .then((result) => result.data)
    .catch((error) => sendError(error));

const getStats = async (collection?: string) => {
  const stats = (await getStatsFunction(collection).then((result) => result.data)) as unknown as Stats;
  // .catch((error) => sendError(error));
  return stats;
};

const getNextNumber = (collection: string) =>
  getNextNumberFunction(collection)
    .then((result) => result.data)
    .catch((error) => sendError(error));

export { reindexQuestions, getStats, getNextNumber };
