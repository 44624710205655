import { lazy } from 'react';
import { RouteProps } from '../index';
import { CNavGroup, CNavItem } from '@coreui/react-pro';
import { cilApps, cilLibraryAdd, cilPageview, cilSettings } from '@coreui/icons-pro';
import { cilUser } from '@coreui/icons-pro/js/linear/cil-user';
import Users from '../../pages/elf/Users';

const Configuration = lazy(() => import('../../pages/elf/Configuration'));
const ExamBuilder = lazy(() => import('../../pages/elf/ExamBuilder'));
const ExamReview = lazy(() => import('../../pages/elf/ExamReview'));

export const elfRoutes: RouteProps[] = [
  { path: '/elf', exact: true, name: 'Elite Learning Feature', protect: true, permissions: ['elf:view'] },
  {
    path: '/elf/configuration',
    exact: true,
    name: 'Configuration',
    component: Configuration,
    protect: true,
    permissions: ['elf:view', 'elf:configure'],
  },
  {
    path: '/elf/exam-builder',
    exact: true,
    name: 'Exam Builder',
    component: ExamBuilder,
    protect: true,
    permissions: ['elf:view', 'elf:builder'],
  },
  {
    path: '/elf/exam-review',
    exact: true,
    name: 'Exam Review',
    component: ExamReview,
    protect: true,
    permissions: ['elf:view', 'elf:review'],
  },
  {
    path: '/elf/users',
    exact: true,
    name: 'Users',
    component: Users,
    protect: true,
    permissions: ['elf:users'],
  },
];

export const elfNav = [
  {
    component: CNavGroup,
    name: 'ELF',
    to: '/elf',
    icon: cilApps,
    items: [
      {
        component: CNavItem,
        name: 'Configuration',
        to: '/elf/configuration',
        icon: cilSettings,
      },
      {
        component: CNavItem,
        name: 'Exam Builder',
        to: '/elf/exam-builder',
        icon: cilLibraryAdd,
      },
      {
        component: CNavItem,
        name: 'Exam Review',
        to: '/elf/exam-review',
        icon: cilPageview,
      },
      {
        component: CNavItem,
        name: 'Users',
        to: '/elf/users',
        icon: cilUser,
      },
    ],
  },
];
