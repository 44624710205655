import { omit } from 'lodash';

import { ADD_BOOKMARK, CLEAR_USER, REMOVE_BOOKMARK, UPDATE_USER } from '../../constants';
import { UserActionTypes } from '../actions';

export interface UserState {
  name: string;
  email: string;
  bookmarks: {
    [id: string]: { collection: string; id: string; question: string };
  };
}

const initialState: UserState = {
  name: '',
  email: '',
  bookmarks: {},
};

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case UPDATE_USER: {
      return {
        ...state,
        name: action.payload.name,
        email: action.payload.email,
        bookmarks: action.payload.bookmarks,
      };
    }
    case CLEAR_USER: {
      return {
        ...state,
        name: '',
        email: '',
        bookmarks: {},
      };
    }
    case ADD_BOOKMARK: {
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          [action.id]: {
            id: action.id,
            collection: action.collection,
            question: action.question,
          },
        },
      };
    }
    case REMOVE_BOOKMARK: {
      return {
        ...state,
        bookmarks: omit(state.bookmarks, action.id),
      };
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
