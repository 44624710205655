import { combineReducers } from 'redux';

import userReducer, { UserState } from './userReducer';
import globalReducer, { GlobalState } from './globalReducer';
import authReducer, { AuthState } from './authReducer';

export interface ReducerState {
  auth: AuthState;
  global: GlobalState;
  user: UserState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  global: globalReducer,
  user: userReducer,
});

export default rootReducer;
export * from './authReducer';
export * from './userReducer';
export * from './globalReducer';
