import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import localForage from 'localforage';
import thunk from 'redux-thunk';

import rootReducer from './reducers';

const storage = localForage.createInstance({
  name: 'ReduxPersist',
});

// Middleware: Redux Persist Config
const persistConfig = {
  // Root?
  key: 'primary',
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  whitelist: ['auth', 'user'],
  // Blacklist (Don't Save Specific Reducers)
  blacklist: ['global'],
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// Redux: Store
const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(thunk)));

// Middleware: Redux Persist Persistor
// @ts-ignore
let persistor = persistStore(store);

// Exports
export { store, persistor };
