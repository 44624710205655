import {
    CButton,
    CCard,
    CCardBody,
    CCardHeader,
    CCol,
    CFormInput,
    CFormLabel,
    CFormSelect,
    CProgress,
    CProgressBar,
    CRow,
    CSpinner
} from '@coreui/react-pro';
import {flatten, uniq} from 'lodash';
import React, {FC, useEffect, useState} from 'react';
import {collectionMapping} from '../../constants';
import {deleteDocument, getDocuments, saveDocument, searchDocumentsBySeries} from '../../firebase/firestore';
import {getElfOptions} from '../../firebase/models/elf';
import {sendError, sendInfo} from '../../helpers';
import {Question} from '../../types';
import {reindexQuestions} from "../../firebase/models/search";
import {Timestamp} from "firebase/firestore";

const Clone: FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [questionsToClone, setQuestionsToClone] = useState<{ [collection: string]: Question[] }>({});

    const [series, setSeries] = useState<string[]>([]);
    const [selectedSeries, setSelectedSeries] = useState<string | undefined>(undefined);
    const [name, setName] = useState('');
    const [totalCloned, setTotalCloned] = useState(0);
    const [totalQuestions, setTotalQuestions] = useState(-1);
    const [started, setStarted] = useState(false);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (totalCloned === totalQuestions) {
            setTimeout(() => setCompleted(true), 1000);
        }
    }, [totalCloned, totalQuestions]);

    useEffect(() => {
        getElfOptions()
            .then(async (doc: any) => {
                const allSeries = uniq([
                    ...Object.keys(doc?.sergeant?.series || {}).map((key) => key),
                    ...Object.keys(doc?.lieutenant?.series || {}).map((key) => key),
                    ...Object.keys(doc?.captain?.series || {}).map((key) => key),
                ]);
                setSeries(allSeries.sort());
            })
            .catch((e: any) => sendError(e))
            .then(() => setLoading(false));
    }, []);

    const updateModifiedTime = async () => {
        const docs = await getDocuments('LearningFeature');
        console.log(docs.length);
        for (const doc of docs) {
            console.log(doc);
            await saveDocument('LearningFeature', doc, doc.objectID);
            console.log(`${doc.id} updated modified date ${Timestamp.fromDate(new Date())}`);
        }
    }

    const collectQuestions = async (series: string) => {
        const mapping: any = {};
        for (const collection of Object.keys(collectionMapping)) {
            const currentQuestions: any[] = [];
            const collectionSeriesQuestions = await searchDocumentsBySeries(collection, series);

            for (const question of collectionSeriesQuestions) {
                const q = question as any;

                q.collection = collection;

                if (q.id) {
                    delete q.id;
                }

                //if (q.objectID) {
                //  delete q.objectID;
                //}

                currentQuestions.push(q);
            }
            mapping[collection] = currentQuestions;
        }
        setQuestionsToClone(mapping);
    };

    const seriesSelected = async (series: string) => {
        setSelectedSeries(series);
        setQuestionsToClone({});
        await collectQuestions(series);
    };

    const startClone = async () => {
        setStarted(true);
        const questions = flatten(Object.values(questionsToClone));

        setTotalQuestions(questions.length);

        for (const [i, question] of Object.entries(questions)) {
            if (question.collection) {
                question.series = [name];
                await saveDocument(question.collection, question).then((doc) => {
                    sendInfo(
                        `"Series cloned: ${selectedSeries}" to "${name}" -- ${doc?.id}: ${parseInt(i, 10) + 1} of ${
                            questions.length
                        } with a new id of ${doc?.id}`,
                        {type: 'Cloned Series'},
                    );
                    setTotalCloned(parseInt(i, 10) + 1);
                });
            }
        }
    };

    const startDelete = async () => {
        setStarted(true);
        const questions = flatten(Object.values(questionsToClone));

        for (const [i, question] of Object.entries(questions)) {
            console.log(question);
            if (question.collection && question.objectID) {
                await deleteDocument(question.collection, question.objectID).then(() => {
                    sendInfo(
                        `"Series deleted: ${selectedSeries} -- ${question.objectID}: ${parseInt(i, 10) + 1} of ${questions.length}`,
                        {type: 'Delete Series'},
                    );
                    setTotalCloned(parseInt(i, 10) + 1);
                });
            }
        }
    };

    const syncSearch = () => reindexQuestions({
        collections: ['LearningFeature', 'LessonQA', 'PracticeExam', 'TargetedReview', 'TrueFalse'],
        clearIndex: true,
    })

    return (
        <CRow xs={{cols: 1}} sm={{cols: 2}} lg={{cols: 3}} xl={{cols: 4}}>
            <CCol>
                <CCard className={`h-100 border-top- border-top-3`}>
                    <CCardHeader>Select Series</CCardHeader>
                    <CCardBody>
                        {/*<CButton color="danger" onClick={() => syncSearch()}>*/}
                        {/*    reindex*/}
                        {/*</CButton>*/}
                        {/*<CButton color="danger" onClick={() => updateModifiedTime()}>*/}
                        {/*    update modifed*/}
                        {/*</CButton>*/}
                        {loading && (
                            <CCol>
                                <CSpinner/>
                            </CCol>
                        )}
                        {!loading && (
                            <CFormSelect
                                aria-label="Default select example"
                                options={series}
                                onChange={(item) => seriesSelected(item.target.value)}
                                disabled={started}
                            />
                        )}
                        {selectedSeries && (
                            <div className="mt-3">
                                <CFormLabel htmlFor="newSeriesName">New Series Name</CFormLabel>
                                <CFormInput
                                    type="text"
                                    id="newSeriesName"
                                    placeholder="Series Name"
                                    onChange={(e) => setName(e.target.value)}
                                    disabled={started}
                                />
                            </div>
                        )}
                    </CCardBody>
                </CCard>
            </CCol>
            <CCol>
                {selectedSeries && (
                    <CCard className={`h-100 border-top- border-top-3`}>
                        <CCardHeader>
                            {selectedSeries} <b>cloned to</b> {name}
                        </CCardHeader>
                        <CCardBody className="text-capitalize">
                            {!Object.keys(questionsToClone).length && (
                                <CCol>
                                    <CSpinner/>
                                </CCol>
                            )}
                            {Object.keys(questionsToClone).map((collection) => {
                                return (
                                    <div key={collection}>
                                        {collectionMapping[collection].name}: {questionsToClone[collection].length}
                                    </div>
                                );
                            })}
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                {totalCloned === 0 && (
                                    <>
                                        <CButton disabled={!name.length} color="primary" onClick={() => startClone()}>
                                            Clone Series
                                        </CButton>
                                        <CButton disabled={!name.length} color="danger" onClick={() => startDelete()}>
                                            Delete Series
                                        </CButton>
                                    </>
                                )}
                            </div>
                        </CCardBody>
                    </CCard>
                )}
            </CCol>
            <CCol>
                {started && (
                    <CCard className={`h-100 border-top- border-top-3`}>
                        <CCardHeader>Cloning</CCardHeader>
                        <CCardBody>
                            Total Questions: {totalQuestions}
                            <CProgress className="mb-3 mt-3">
                                {!completed ? (
                                    <CProgressBar
                                        color="info"
                                        variant="striped"
                                        animated={false}
                                        value={(totalCloned / totalQuestions) * 100}
                                    />
                                ) : (
                                    <CProgressBar color="success" value={100}/>
                                )}
                            </CProgress>
                            <p>Cloned Questions: {totalCloned}</p>
                            {!completed && <b>Do Not Leave Until Completed</b>}
                        </CCardBody>
                    </CCard>
                )}
            </CCol>
        </CRow>
    );
};

export default Clone;
