import {
    addDoc,
    collection,
    connectFirestoreEmulator,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    getFirestore,
    orderBy,
    query,
    setDoc,
    Timestamp,
    where
} from 'firebase/firestore';
import {firebaseApp} from './firebase';

const db = getFirestore(firebaseApp);

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_EMULATOR) {
    connectFirestoreEmulator(db, 'localhost', 8080);
}

export const getDocument = async (collectionName: string, documentID: string) => {
    const docRef = doc(db, collectionName, documentID);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    }

    return null;
};

export const saveDocument = async (collectionName: string, data: any, documentID?: string) => {
    const modified = Timestamp.fromDate(new Date());
    if (!documentID) {
        return addDoc(collection(db, collectionName), {
            ...data,
            created: modified
        });
    }
    return setDoc(doc(db, collectionName, documentID), {
        ...data,
        modified
    });
};

export const deleteDocument = async (collection: string, id: string) => deleteDoc(doc(db, collection, id));

export const getDocuments = async (collectionName: string, sortby?: string) => {
    const documents: any[] = [];

    const collectionRef = collection(db, collectionName);
    let toGet;

    if (sortby) {
        toGet = query(collectionRef, orderBy("date", "desc"));
    } else {
        toGet = collectionRef;
    }

    const querySnapshot = await getDocs(toGet);

    querySnapshot.forEach((doc: any) => {
        const item = doc.data();
        item.objectID = doc.id;
        documents.push(item);
    });

    return documents;
};

export const searchDocumentsBySeries = async (collectionName: string, series: string) => {
    const documents: any[] = [];
    const collectionRef = collection(db, collectionName);
    const querySnapshot = await getDocs(query(collectionRef, where('series', 'array-contains', series)));

    querySnapshot.forEach((doc: any) => {
        const item = doc.data();
        item.objectID = doc.id;
        documents.push(item);
    });

    return documents;
};

export const getTimestamp = () => Timestamp.now();
