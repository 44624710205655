import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';

import { firebaseApp } from './firebase';

const functions = getFunctions(firebaseApp);

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOCAL_EMULATOR) {
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

export const getFunction = (name: string) => httpsCallable(functions, name);
