import { sendError } from '../../helpers';
import { RemoteConfig } from '../../types';
import { getFunction } from '../functions';

const syncELF = getFunction('syncELF');
const getConfig = getFunction('getConfig');
const saveConfig = getFunction('saveConfig');
const getOptions = getFunction('getElfOptions');

const getUser = getFunction('getElfUser');
// const saveUser = getFunction('saveElfUser');
const deleteUser = getFunction('deleteElfUser');

const sync = () =>
  syncELF()
    .then((result) => result.data)
    .catch((error) => sendError(error) && error);

const getElfConfig = () =>
  getConfig()
    .then((result: { data: any }) => result)
    .catch((error: any) => sendError(error) && error);

const saveElfConfig = (data: RemoteConfig) =>
  saveConfig(data)
    .then((result: { data: any }) => result.data)
    .catch((error: any) => sendError(error) && error);

const getElfOptions = () =>
  getOptions()
    .then((result: { data: any }) => result.data)
    .catch((error: any) => sendError(error) && error);

const getElfUser = (data: { username?: string; id?: string }) =>
  getUser(data)
    .then(({ data }) => data)
    .catch((error: any) => sendError(error) && error);

const saveElfUser = () =>
  getOptions()
    .then(({ data }) => data)
    .catch((error: any) => sendError(error) && error);

const deleteElfUser = async (id: string) => {
  return deleteUser({ id })
    .then(({ data }) => data)
    .catch((error: any) => sendError(error) && error);
};

export { sync, getElfConfig, saveElfConfig, getElfOptions, getElfUser, saveElfUser, deleteElfUser };
