import { lazy } from 'react';

import { RouteProps } from '../index';

const UserList = lazy(() => import('../../pages/users/List'));
const Bookmarks = lazy(() => import('../../pages/user/Bookmarks'));

export const userRoutes: RouteProps[] = [
  { path: '/user', exact: true, name: 'User', component: UserList, protect: true, permissions: [] },
  { path: '/user/profile', name: 'Profile', protect: true, permissions: [] },
  { path: '/user/bookmarks', name: 'Bookmarks', component: Bookmarks, protect: true },
];
